import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide38/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide38/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide38/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide38/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide38/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">Cómo sacar el costo de ventas de una empresa de servicios</h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      ¿Tienes una empresa de servicios? Si es así, seguramente sabrás que
      determinar el precio de los servicios no siempre resulta sencillo. Para
      asegurarte de que tu negocio sea rentable, considera estos consejos para
      fijar los precios de tus servicios.{" "}
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Muchos negocios basados en servicios enfrentan dificultades al establecer
    una estrategia de precios justa y rentable. A diferencia de la fijación de
    precios de productos, no siempre es posible cuantificar todos los costos
    asociados con la prestación de un servicio.
    <br />
    <br />
    Los gastos relacionados con la oferta de un servicio son más subjetivos que
    los gastos involucrados en la fabricación de un producto. La cantidad que
    cobras a los clientes no siempre se correlaciona directamente con lo que
    pagas por realizar los servicios.{" "}
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Análisis de entorno de mercado.",
      },
      {
        key: 2,
        text: "Cómo conocer a los consumidores.",
      },
      {
        key: 3,
        text: "Cálculo de costos por tiempo invertido.",
      },
      {
        key: 4,
        text: "Fijación de precios dentro del mercado.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
